/* USERS */
export const GET_USERS_AVALIABILITY_SUCCESS = "GET_USERS_AVALIABILITY_SUCCESS"
export const GET_USERS_AVALIABILITY_FAIL = "GET_USERS_AVALIABILITY_FAIL"
export const API_AVALIABILITY_ERROR = "API_ERROR"
export const GET_USERS_AVALIABILITY_LOADING = "GET_USERS_AVALIABILITY_LOADING"
export const GET_DAYS_SUCCESS = "GET_DAYS_SUCCESS"
export const FETCH_AVAILABILITY_SUCCESS = "FETCH_AVAILABILITY_SUCCESS";
export const FETCH_AVAILABILITY_FAIL = "FETCH_AVAILABILITY_FAIL";
export const SAVE_AVAILABILITY_SUCCESS = "SAVE_AVAILABILITY_SUCCESS";
export const SAVE_AVAILABILITY_FAIL = "SAVE_AVAILABILITY_FAIL";
export const API_AVAILABILITY_ERROR = "API_AVAILABILITY_ERROR";
export const AVAILABILITY_LOADING = "AVAILABILITY_LOADING";





