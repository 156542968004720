export const dayConvertion = {
    [1]: 'Domingo',
    [2]: 'Segunda-feira',
    [3]: 'Terça-feira',
    [4]: 'Quarta-feira',
    [5]: 'Quinta-feira',
    [6]: 'Sexta-feira',
    [7]: 'Sábado',
    [8]: 'Todos os dias',
  };
