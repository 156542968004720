import { api } from "../../services/api";
import { processErrorMessage } from "../../helpers/errors";
import {
  GET_USERS_AVALIABILITY_SUCCESS,
  GET_USERS_AVALIABILITY_FAIL,
  API_AVALIABILITY_ERROR,
  GET_USERS_AVALIABILITY_LOADING,
  GET_DAYS_SUCCESS,
  FETCH_AVAILABILITY_SUCCESS,
  FETCH_AVAILABILITY_FAIL,
  SAVE_AVAILABILITY_SUCCESS,
  SAVE_AVAILABILITY_FAIL,
  AVAILABILITY_LOADING,
} from "./actionTypes";

export const getUsersAv = (page = 1, name = '') => dispatch => {
  dispatch({ type: GET_USERS_AVALIABILITY_LOADING })
  api.get(`account/avTeam?page=${page}&name=${name}`, { withCredentials: true })
    .then(response => {

      dispatch({
        type: GET_USERS_AVALIABILITY_SUCCESS,
        payload: response.data,
      })
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR)
    })
}

export const getDays = () => (dispatch) => {
  dispatch({ type: GET_USERS_AVALIABILITY_LOADING });

  api
    .get("/availability/days", { withCredentials: true })
    .then((response) => {
      dispatch({
        type: GET_DAYS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      processErrorMessage(dispatch, error, API_ERROR);
      dispatch({ type: API_AVALIABILITY_ERROR });
    });
};

export const getTeamAvailabilitySuccess = users => ({
  type: GET_USERS_AVALIABILITY_SUCCESS,
  payload: users,
});

export const getTeamAvailabilityFail = error => ({
  type: GET_USERS_AVALIABILITY_FAIL,
  payload: error,
});

export const apiAvCanceledError = error => ({
  type: API_AVALIABILITY_ERROR,
  payload: error,
});

// Fetch Availability
export const fetchAvailability = (userId) => (dispatch) => {
  dispatch({ type: AVAILABILITY_LOADING });

  api
    .get(`/availability/getAvailability?userId=${userId}`, { withCredentials: true })
    .then((response) => {
      dispatch({
        type: FETCH_AVAILABILITY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      processErrorMessage(dispatch, error, API_AVAILABILITY_ERROR);
      dispatch({
        type: FETCH_AVAILABILITY_FAIL,
        payload: error,
      });
    });
};

// Save Availability
export const saveAvailability = (availabilityList) => (dispatch) => {
  dispatch({ type: AVAILABILITY_LOADING });
  api
    .post("/availability/saveAvailability", availabilityList, { withCredentials: true })
    .then((response) => {
      dispatch({
        type: SAVE_AVAILABILITY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      processErrorMessage(dispatch, error, API_AVAILABILITY_ERROR);
      dispatch({
        type: SAVE_AVAILABILITY_FAIL,
        payload: error,
      });
    });
};