import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

//Simple bar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_EMPLOYEE_PSY } from 'constants/roles'

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu('#side-menu')

    let matchingMenuItem = null
    const ul = document.getElementById('side-menu')
    const items = ul.getElementsByTagName('a')
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add('active')
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show')
    }

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show') // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add('mm-show') // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add('mm-show') // li
              parent5.childNodes[0].classList.add('mm-active') // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    const user = this.props.user
    let role = ''
    if(user){
      role = user.role
    }
    return (
      <React.Fragment>
        <SimpleBar className='h-100' ref={this.refDiv}>
          <div id='sidebar-menu'>
            <ul className='metismenu list-unstyled' id='side-menu'>
              {role === ROLE_ADMIN &&
                <li>
                  <Link to='/#' className=''>
                    <i className='bx bx-home-circle' />
                    {
                      /*
                        <span className='badge rounded-pill bg-info float-end'>
                          04
                        </span>
                      */
                    }
                    <span>Dashboard</span>
                  </Link>
                </li>
              }
              <li className='menu-title'>Atendimento</li>
              {
                [ROLE_EMPLOYEE, ROLE_EMPLOYEE_PSY].includes(role) &&
                <li>
                  <Link to='/schedules' className=''>
                    <i className='bx bx-calendar' />
                    <span>Agenda</span>
                  </Link>
                </li>
              }
              {
                role === ROLE_ADMIN &&
                <li>
                  <Link to='/appointments' className=''>
                    <i className="bx bx-calendar" />
                    <span>Agenda</span>
                  </Link>
                </li>
              }
              { role !== ROLE_EMPLOYEE &&
              <li>
                <Link to='/my-requests' className=''>
                  <i className='bx bx-task' />
                  {role === ROLE_ADMIN ? <span>Solicitações</span> :
                    <span>Minhas solicitações</span>}
                </Link>
              </li>
              }
              
              {
                role === ROLE_ADMIN &&
                <li>
                  <Link to='/contacts-list' className=''>
                    <i className='bx bx-user-circle' />
                    <span>Usuários</span>
                  </Link>
                </li>
              }

              {
                role === ROLE_ADMIN &&
                <li>
                  <Link to='/appointments-list' className=''>
                    <i className='dripicons-clock' />
                    <span>Compromissos</span>
                  </Link>
                </li>
              },
              {/* {
                role === ROLE_ADMIN &&
                <li>
                  <Link to='/schedules' className=''>
                    <i className='bx bx-chat' />
                    <span>Regras de atendimento</span>
                  </Link>
                </li>
              } */}
              {
                role === ROLE_ADMIN &&
                <li className='menu-title'>Relatórios</li>
              }
              {
                role === ROLE_ADMIN &&
                <li>
                  <Link to='/canceled-requests-report' className=''>
                    <i className='bx bxs-report' />
                    <span>Solicitações rejeitadas</span>
                  </Link>
                </li>
              }
              {
                role === ROLE_ADMIN &&
                <li className='menu-title'>Configurações</li>
              }
              {
                role === ROLE_ADMIN &&
                <li>
                  <Link to='/team-availability' className=''>
                  <i className='bx bxs-stopwatch' />
                    <span>Disponibilidade da equipe</span>
                  </Link>
                </li>
              }
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  user: PropTypes.object,
}

const mapStateToProps = state => {
  const { userData } = state.User
  return { user: userData }
}


export default withRouter(connect(mapStateToProps, {})(SidebarContent))