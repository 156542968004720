import {
  GET_USERS_AVALIABILITY_SUCCESS,
  GET_USERS_AVALIABILITY_FAIL,
  API_AVALIABILITY_ERROR,
  GET_USERS_AVALIABILITY_LOADING,
  GET_DAYS_SUCCESS,
  FETCH_AVAILABILITY_SUCCESS,
  FETCH_AVAILABILITY_FAIL,
  SAVE_AVAILABILITY_SUCCESS,
  SAVE_AVAILABILITY_FAIL,
  AVAILABILITY_LOADING,
} from "./actionTypes";

const INIT_STATE = {
  requests: [],
  pageCount: 0,
  totalCount: 0,
  page: 1,
  error: "",
  loading: false,
  users: [],
  days: [],
  availability: [], // Nova propriedade para armazenar os dados de disponibilidade
};

const settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_AVALIABILITY_LOADING:
    case AVAILABILITY_LOADING: // Ambos os casos compartilham o mesmo comportamento
      return {
        ...state,
        error: "",
        loading: true,
      };
    case GET_USERS_AVALIABILITY_SUCCESS:
      return {
        ...state,
        error: "",
        requests: action.payload.attendance,
        pageCount: action.payload.pageCount,
        totalCount: action.payload.totalCount,
        users: action.payload.users,
        page: action.payload.page,
        loading: false,
      };
    case GET_USERS_AVALIABILITY_FAIL:
    case FETCH_AVAILABILITY_FAIL:
    case SAVE_AVAILABILITY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_DAYS_SUCCESS:
      return {
        ...state,
        error: "",
        days: action.payload,
        loading: false,
      };
    case FETCH_AVAILABILITY_SUCCESS:
      return {
        ...state,
        error: "",
        availability: action.payload, // Atualiza os dados de disponibilidade
        loading: false,
      };
    case SAVE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        error: "",
        availability: [...state.availability, ...action.payload], // Atualiza a lista de disponibilidade com os dados retornados
        loading: false,
      };
    case API_AVALIABILITY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default settings;
