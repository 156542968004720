import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Label, Row, Col, Form, Alert } from 'reactstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserRoles, changeRole, toggleChangeRole } from 'store/auth/changeRole/actions';
import PropTypes from 'prop-types';
import {
    END_USER,
    EMPLOYEE,
    EMPLOYEE_PSY,
    ADMIN,
} from 'constants/roles';

const roleNames = {
    [END_USER]: 'Solicitante',
    [ADMIN]: 'Administrador',
    [EMPLOYEE]: 'Intérprete',
    [EMPLOYEE_PSY]: 'Psicopedagogo',
};

class ChangeRoleModal extends Component {
    componentDidMount() {
        this.props.onGetUserRoles();
    }
    render() {
        const { isOpen, toggleChangeRole, loading, errorMessage, userRoles } = this.props;
        return (
            <Modal isOpen={isOpen} toggle={toggleChangeRole} className="change-interpreter-modal">
                <ModalHeader tag='h4'>
                    Informe o perfil desejado
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{ roleId: '' }}
                        validationSchema={Yup.object().shape({
                            roleId: Yup.number().required('Selecione um tipo de perfil'),
                        })}
                        onSubmit={values => {
                            const payload = {
                                values
                            };
                            this.props.onChangeRole(this.props.history, payload);
                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <Row>
                                    <Col className='col-12'>
                                        <div className='mb-3'>
                                            <Field
                                                className='form-control'
                                                as='select'
                                                aria-label={'Caixa de seleção do perfil'}
                                                name='roleId'
                                                onChange={event => setFieldValue('roleId', event.target.value)}
                                            >
                                                <option value=''>Selecione um perfil</option>
                                                {userRoles ? userRoles.map((role, index) => (
                                                    <option key={index} value={role.roleId}>
                                                        {roleNames[role.roleId]}
                                                    </option>
                                                )) : null}
                                            </Field>
                                            <ErrorMessage
                                                name='roleId'
                                                component='div'
                                                className='invalid-feedback'
                                            />
                                        </div>
                                        {errorMessage && (
                                            <Alert color='danger'>{errorMessage}</Alert>
                                        )}
                                        <div className='text-end'>
                                            <button
                                                type='submit'
                                                className='btn btn-success me-2'
                                                disabled={loading}
                                                onClick={() => {
                                                    const payload = {
                                                        values
                                                    };
                                                    this.props.onChangeRole(this.props.history, payload);
                                                }}
                                                aria-label='Confirmar e mudar perfil'
                                            >
                                                {loading ? 'Aguarde...' : 'Salvar'}
                                            </button>
                                            <button
                                                type='button'
                                                className='btn btn-danger'
                                                onClick={toggleChangeRole}
                                                aria-label='Cancelar e fechar modal'
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    userRoles: state.changeRole.userRoles,
    errorMessage: state.changeRole.error,
    loading: state.changeRole.loading,
    isOpen: state.changeRole.changeRoleModal,
});

const mapDispatchToProps = dispatch => ({
    onGetUserRoles: () => dispatch(getUserRoles()),
    onChangeRole: (history, payload) => dispatch(changeRole(history, payload)),
    toggleChangeRole: () => dispatch(toggleChangeRole()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeRoleModal));